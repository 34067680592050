<template>
	<div class="login-body flex flex-column align-items-center justify-content-center">
    <div class="login-panel p-5 pt-6 p-fluid flex flex-column justify-content-center">

        
        <h4 class="font-bold mb-1">A1 Catering</h4>
        <span class="mb-4">Yönetim Paneli</span>

        <span class="p-input-icon-left mb-3">
            <i class="pi pi-user"></i>
            <InputText id='email' type="text"  v-model="loginForm.email" placeholder="E-Posta Adresiniz" />
        </span>

        <span class="p-input-icon-left mb-4">
            <i class="pi pi-key"></i>
            <InputText id='password' type="password" v-model="loginForm.password" placeholder="Şifre" />
        </span>
        
  
        <Button type="button" label="GİRİŞ YAP"  @click="login"></button>

        <Message :severity="message.type" v-if="message.show">{{message.content}}</Message>

    </div>

</div>

</template>

<script>
import store from "@/store";
import AuthenticationService from "@/services/authenticationService";


export default {

    _authService : null,
    created() {
        this._authService = new AuthenticationService();
    },
    data() {
        return {
            message: {
                type:'info',
                content:'',
                show:false
            },
            loginForm: {
                email: '',
                password: '',
                rememberMe: false
            }
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {
        async login() {
            const response = await this._authService.loginWithEmailAndPassword(this.loginForm.email, this.loginForm.password);
            if(response.isSuccess)
            {
                if(response.data.projectList.length <= 0)
                {
                    this.message.content = "Yetkili olduğunuz proje bulunmamaktadır";
                    this.message.show = true;
                    this.message.type ='error';

                    return;
                }

                this.message.show = true;
                this.message.content = 'Kullanıcı adı ve şifreniz doğru.'
                this.message.type = 'success';

                store.commit("setUser", {userData: response.data});
                store.commit("setProjects", {userProjects: response.data.projectList});
                store.commit("setToken", response.data.token);
                store.commit("setRefreshToken", response.data.refreshToken);
                store.commit("setExpireDate", response.data.loginExpireDate)
                setTimeout(() => {
                    this.message.show = false;
                    this.$router.push({name:'home'});
                }, 2000);
            }
            else
            {
                this.message.content = response.message;
                this.message.show = true;
                this.message.type ='error';
            }
        }
    }
}
</script>
