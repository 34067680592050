import appAxios from "@/utils/appAxios";

export default class AuthenticationService {
    #defaultUrl = "/api/Auth";
    async loginWithEmailAndPassword(email, password) {
        const loginRequest = {
            email: email,
            password: password
        };
        const response = await appAxios.post(this.#defaultUrl, loginRequest);
        if(response.status == 200)
        {
            return {
                isSuccess :true,
                data:response.data,
                message: ""
            };

        }
        else {
            return {
                isSuccess :false,
                data:{},
                message: "E-Posta yada Şifre Hatalı"
            };
        }

    }

    async loginWithRefreshToken(refreshToken) {
        const loginRequest = {
            refreshToken: refreshToken
        };
        const response = await appAxios.post(this.#defaultUrl + "/refresh-token", loginRequest);
        if(response.status == 200)
        {
            return {
                isSuccess :true,
                data:response.data,
                message: ""
            };

        }
        else {
            return {
                isSuccess :false,
                data:{},
                message: "E-Posta yada Şifre Hatalı"
            };
        }
    }

}